export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    description: '',
    buttonText: 'Add',
    ballotTypeList: [],
    radioElection: 'nonCumulative',
    formData: {
      description: '',
      fullDescription: '',
      title: '',
      ballotTypeCode: '',
      noOptionDescription: '',
      yesOptionDescription: '',
      electionCumulation: 'nonCumulative',
      numOfOptions: 0,
      completeByDate: null,
      isSecret: false
    }
  }
}
