export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    buttonText: 'Add',
    file: null,
    base64pdf: '',
    pageCount: [],
    scale: 1,
    imageContent: null,
    attachment: false,
    ballotResponseID: 0,
    ballotResponseTemp: null,
    ballotID: 0,
    ballotType: '',
    ballot: null,
    radio: 'no',
    candidateRadioGroup: [],
    candidateCheckboxGroup: [],
    multipleChoiceCheckboxGroup: [],
    formData: {},
    name: '',
    data: [],
    tempAddress: '',
    locationFilterQuery: null,
    addressDropDownList: [],
    responseCandidates: [],
    responseMultipleChoices: [],
    writeInName: '',
    alreadyVoted: false
  }
}
