<template>
  <PageContent>
    <div class="level pl-2">
      <div class="level-left level-item title">{{ $t('evotingResponses.title') }}</div>
    </div>
    <div class="pb-5">
      <b-button
        @click.prevent="createBallotResponse()"
        type="is-primary"
        size="is-small"
        rounded
        :style="{
          ...(breakpoint === 'desktop'
            ? {}
            : {
                width: '100%'
              })
        }"
      >
        Add Response
      </b-button>
    </div>
    <div>
      <ModernTable
        :region="region"
        :filters="filters"
        :rows="rows"
        :columns="columns"
        :pagination="{
          perPage: 12
        }"
        :loading="loading"
      />
    </div>
    <Modal :toggle.sync="toggle">
      <BallotResponseModal :ballotResponse="selectedBallotResponse" :isHomeOwner="isHomeOwner">
      </BallotResponseModal>
    </Modal>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import BallotResponseModal from './components/ballotResponseModal'
import Modal from '@/components/Modal'

export default {
  name: 'EvotingResponses',
  components: {
    PageContent,
    ModernTable,
    Modal,
    BallotResponseModal
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser']),
      breakpoint: state => (state && state.win ? state.win.breakpoint : null)
    })
  },

  created() {},

  mounted() {
    this.reload()
  },

  watch: {
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  methods,

  i18n: {
    messages: {
      en: {
        evotingResponses: { title: 'eVoting Responses' }
      }
    }
  }
}
</script>
