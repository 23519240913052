<template>
  <div ballot-response-modal id="ballotResponseModal" class="section">
    <div class="container">
      <div class="is-size-4">{{ `${buttonText} Ballot Response` }}</div>
      <div class="is-size-6" v-if="ballot && ballot.ballotType">
        {{ `Ballot Type: ${ballotType}` }}
      </div>
      <div class="is-size-6" v-if="ballot && ballot.description">
        {{ `Description: ${ballot.description}` }}
      </div>
      <div
        class="is-size-6"
        v-if="
          ballot &&
            ballot.ballotType === 'election' &&
            ballot.electionVotingIsCumulative &&
            ballot.electionVotingIsCumulative === true
        "
      >
        Type: Cumulative Vote
      </div>
      <div class="is-size-6" v-else-if="ballot && ballot.ballotType === 'election'">
        Type: Non-Cumulative Vote
      </div>
      <div
        class="is-size-6"
        v-else-if="
          ballot &&
            ballot.ballotType === 'multiple-choice' &&
            ballot.multipleChoiceNumOptionsSelectable
        "
      >
        {{ `Number of Selectable Options: ${ballot.multipleChoiceNumOptionsSelectable}` }}
      </div>
      <div
        class="is-size-6 pb-5"
        v-if="
          ballot &&
            ballot.ballotType &&
            ballot.numOfElectionPositions &&
            ballot.ballotType === 'election'
        "
      >
        {{ `Number of Votes: ${ballot.numOfElectionPositions}` }}
      </div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="columns">
                  <div class="column is-6">
                    <label class="label">Select Location / Unit</label>
                    <b-autocomplete
                      size="is-small"
                      field="label"
                      icon="magnify"
                      :loading="isFetching"
                      placeholder="e.g. Location / Unit Search"
                      :data="data"
                      :open-on-focus="openOnFocus"
                      v-model="locationFilterQuery"
                      required="true"
                      @typing="getAsyncData"
                      @select="option => (selected = option)"
                      :disabled="isHomeOwner === true"
                    >
                    </b-autocomplete>
                  </div>
                </div>
                <div v-if="ballot && ballot.ballotType === 'yes-no'">
                  <b-field>
                    <b-radio
                      v-model="radio"
                      name="yesNo"
                      native-value="yes"
                      :disabled="isHomeOwner === true"
                    >
                      {{ ballot.yesOptionDescription }}
                    </b-radio>
                  </b-field>
                  <b-field>
                    <b-radio
                      v-model="radio"
                      name="yesNo"
                      native-value="no"
                      :disabled="isHomeOwner === true"
                    >
                      {{ ballot.noOptionDescription }}
                    </b-radio>
                  </b-field>
                </div>
                <div v-if="ballot && ballot.ballotType === 'election'">
                  <div
                    v-if="
                      ballot.electionVotingIsCumulative &&
                        ballot.electionVotingIsCumulative === true
                    "
                  >
                    <div
                      class="block column is-12"
                      v-for="(c, index) in ballot.ballotCandidates"
                      :key="index"
                    >
                      <div class="columns">
                        <div class="column is-4">
                          {{
                            c.isWriteIn != null && c.isWriteIn === true
                              ? `${c.name} (Write-In)`
                              : c.name
                          }}
                        </div>
                        <div class="column">
                          <b-numberinput
                            min="0"
                            :max="parseInt(ballot.numOfElectionPositions)"
                            :name="`candidateCheckboxGroup${index}`"
                            :vid="`candidateCheckboxGroup${index}`"
                            :id="`${c.ballotCandidateID}`"
                            v-model="candidateCheckboxGroup[index]"
                            :disabled="isHomeOwner === true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-for="(c, index) in ballot.ballotCandidates" :key="index">
                      <b-field>
                        <b-checkbox
                          v-model="candidateRadioGroup"
                          :disabled="isHomeOwner === true"
                          :native-value="c.ballotCandidateID"
                        >
                          {{
                            c.isWriteIn != null && c.isWriteIn === true
                              ? `${c.name} (Write-In)`
                              : c.name
                          }}
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>
                  <div
                    class="columns is-12"
                    v-if="
                      ballot &&
                        ballot.writeInCandidatesEnabled !== undefined &&
                        ballot.writeInCandidatesEnabled === true
                    "
                  >
                    <div class="column is-6 pt-5">
                      <valid-input
                        style="width:50px !important;"
                        name="WriteInName"
                        label="Write-In Name"
                        type="text"
                        vid="WriteInName"
                        placeholder="Write-In Name"
                        spellcheck="true"
                        aria-label="Write-In Name"
                        rules="max:50"
                        class="is-small"
                        v-model="writeInName"
                        :disabled="isHomeOwner === true"
                      />
                    </div>
                    <div class="column is-6 pt-6">
                      <button
                        :disabled="isHomeOwner === true"
                        @click.prevent="addWriteIn()"
                        type="button"
                        class="button is-info"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="ballot && ballot.ballotType === 'multiple-choice'">
                  <div
                    class="block column is-12"
                    v-for="(c, index) in ballot.ballotMultipleChoiceOptions"
                    :key="index"
                    :disabled="isHomeOwner === true"
                  >
                    <b-checkbox
                      v-model="multipleChoiceCheckboxGroup"
                      :native-value="c.ballotMultipleChoiceOptionID"
                      :disabled="isHomeOwner === true"
                    >
                      {{ c.description }}
                    </b-checkbox>
                  </div>
                </div>
                <div class="pt-5">
                  <section class="box" v-if="base64pdf">
                    <div :style="{ textAlign: 'left' }" id="viewpdf" class="has-text-centered">
                      <div>
                        <div>
                          <div
                            v-dragscroll="true"
                            :class="['doc-viewer', { 'zoom-active': scale > 0.9 }]"
                          >
                            <pdf
                              style="display: none;"
                              :src="base64pdf"
                              @num-pages="pageCount = new Array($event)"
                            />
                            <!-- Actual document -->
                            <pdf
                              v-for="(page, index) in pageCount"
                              :key="index"
                              :src="`${base64pdf}`"
                              :page="index + 1"
                              :scale="scale"
                              :resize="true"
                              :style="{ margin: 'auto', width: `${100 * scale}%` }"
                            />
                          </div>
                        </div>
                        <div class="doc-controls has-text-center">
                          <b-field position="is-centered">
                            <div class="control">
                              <b-button @click="zoom(-1)">-</b-button>
                            </div>
                            <div class="control">
                              <b-button @click="zoom(1)">+</b-button>
                            </div>
                          </b-field>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div v-if="isHomeOwner === false">
                    <!-- Show the Upload Form -->
                    <b-field class="file">
                      <b-upload v-model="file" drag-drop accept="application/pdf" class="rectangle">
                        <section class="section">
                          <div class="">
                            <div>
                              <img
                                class="Fill-1-Copy-11"
                                src="@/assets/images/fill-1-copy-11.svg"
                                alt="Upload"
                              />
                            </div>
                            <div v-if="file">
                              {{ file.name }}
                            </div>
                            <div v-else>
                              <div class="Drag-Drop-your-fil">
                                <div class="text-style-1">Drag & Drop</div>
                                <div>your files here to upload</div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>
                  </div>
                </div>
                <div class="pt-6">
                  <button
                    :disabled="isHomeOwner === true"
                    type="submit"
                    class="button is-primary is-rounded"
                  >
                    {{ buttonText }}</button
                  ><span class="pl-3">&nbsp;</span>
                  <b-button
                    :disabled="isHomeOwner === true"
                    v-if="base64pdf"
                    @click.prevent="replaceFile()"
                    type="is-primary"
                  >
                    Replace </b-button
                  ><span class="pl-3">&nbsp;</span>
                  <b-button @click.prevent="closeModal()" type="is-primary">
                    Close
                  </b-button>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import pdf from 'vue-pdf'
import { dragscroll } from 'vue-dragscroll'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  name: 'BallotResponseModal',
  props: {
    ballotResponse: Object,
    isHomeOwner: {
      default: false,
      type: Boolean
    }
  },

  directives: { dragscroll },

  components: {
    ValidationObserver,
    ValidationProvider,
    pdf,
    ValidInput
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    }),
    addressFilterSuggestions() {
      if (
        this.addressFilterQuery &&
        this.addressDropDownList &&
        this.addressDropDownList != undefined
      ) {
        return this.addressDropDownList.filter(
          a => a.label.indexOf(this.addressFilterQuery.toLowerCase()) !== -1
        )
      } else {
        return this.addressDropDownList
      }
    }
  },

  data,
  methods
}
</script>
<style lang="scss"></style>
