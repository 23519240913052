import { ballotResponseStore } from '@/services/BallotResponse/store'
import $ from 'jquery'
import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import parseAddress from '@/utilities/address/parse'
import kms from '@/services/kms'
import Download from '../components/Download'
import { notifyProblem, notifyMessage, notifyWarning } from '@/services/notify'
import { base64toBlob, showPdf } from '@/services/blobutility'

export const methods = {
  async reload() {
    console.debug('in reload()')

    this.ballotID = this.$route.params.id

    await this.loadBallotResponseList()
  },

  async loadBallotResponseList() {
    await ballotResponseStore
      .dispatch('getBallotResponseList', {
        ballotID: this.ballotID
      })
      .then(({ list }) => {
        this.determineResponseRows(list)
        this.loading = false
      })
  },

  determineResponseRows: function(list) {
    const dateFormat = 'LL'

    let filteredList = list

    var rows = filteredList.map(entry => {
      const ownerName = _get(entry, 'owner.name', '')

      //parse address
      const unitAddress = parseAddress({
        address: _get(entry, 'unit.address', '')
      })

      const vote = _get(entry, 'vote', '')
      const isHomeOwner = _get(entry, 'isHomeOwner', false)

      const createdDate = parseDate(_get(entry, 'createdDate', null), dateFormat)

      return {
        ownerName,
        unitAddress,
        vote,
        createdDate,
        details: {
          component: Button,
          props: {
            onClick: () => {
              this.editBallotResponse(entry)
            },
            text: isHomeOwner === true ? 'View' : 'Edit'
          }
        },
        erase: {
          component: Button,
          props: {
            onClick: () => {
              this.confirmDeleteBallotResponse(entry)
            },
            text: 'Delete',
            visible: isHomeOwner === true ? false : true
          }
        },
        document: {
          component: Download,
          props: {
            documentID: _get(entry, ['ballotDocumentID'], 0),
            ballotResponseID: _get(entry, 'ballotResponseID', 0),
            downloadPdf: ballotResponseID => {
              this.downloadPdf(ballotResponseID)
            }
          }
        }
      }
    })

    this.rows = rows
  },

  async downloadPdf(ballotResponseID) {
    if (this.isDebug == true) console.debug('downloadPdf...')

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (!ballotResponseID || ballotResponseID == undefined) {
      notifyProblem('There was a problem downloading this ballot document.')
    }

    try {
      const params = {
        ballotResponseID: ballotResponseID,
        asBase64: true,
        asRawBase64: true
      }

      const path = `/EVoting/BallotResponse/GetFile`
      const returned = await kms.get(path, {
        params
      })

      if (this.isDebug == true)
        console.debug(
          'path base64 path=' +
            path +
            ' - params' +
            JSON.stringify(params) +
            ' - returned=' +
            JSON.stringify(returned)
        )
      if (returned && returned != undefined) {
        loadingComponent.close()

        if (this.isDebug == true) console.debug('base64pdf........................' + returned)
        const contentType = 'application/pdf'
        const blob = base64toBlob(returned, contentType)
        showPdf(blob)
        notifyMessage(`Successfully downloaded this response document.`)
        return
      }
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading this response document.')
      loadingComponent.close()
    }

    loadingComponent.close()
  },

  async confirmDeleteBallotResponse(entry) {
    try {
      this.$buefy.dialog.confirm({
        title: 'Deleting Ballot Response',
        message: `Are you sure you want to <b>delete</b> this response for ${entry.owner.name ||
          ''}, "${entry.vote || ''}"?`,
        confirmText: 'Delete Ballot Response',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => await this.deleteBallotResponse(entry.ballotResponseID)
      })
    } catch (e) {
      console.debug('There was an issue deleting this response.')
    }
  },

  async deleteBallotResponse(ballotResponseID) {
    try {
      await ballotResponseStore.dispatch('deleteBallotResponse', {
        ballotResponseID,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    } catch (e) {
      console.debug('There was an issue deleting this ballot response.')
    }
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async editBallotResponse(entry) {
    this.selectedBallotResponse = entry
    this.isHomeOwner = entry.isHomeOwner
    this.toggle = true
  },

  async createBallotResponse() {
    this.selectedBallotResponse = null
    this.toggle = true
  }
}
