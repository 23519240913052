<template>
  <div>
    <span
      v-if="
        (typeof ballotCandidateID === 'string' || typeof ballotCandidateID === 'number') &&
          documentID > 0
      "
    >
      <button @click.prevent="downloadPdf(ballotCandidateID)">
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          ></path>
        </svg>
      </button>
    </span>
    <span v-else>
      &nbsp;
    </span>
  </div>
</template>

<script>
export default {
  props: {
    ballotCandidateID: [Number, String],
    documentID: [Number, String],
    downloadPdf: Function
  }
}
</script>
