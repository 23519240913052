import { ballotResponseStore } from '@/services/BallotResponse/store'
import { ballotCandidateStore } from '@/services/BallotCandidate/store'
import { ballotStore } from '@/services/Ballot/store'
import { notifyError, notifyMessage } from '@/services/notify'
import $ from 'jquery'
import _get from 'lodash/get'
import debounce from 'lodash/debounce'
import { hoaUtility } from '@/services/Hoa/store'

export const methods = {
  async reload() {
    console.debug('in reload()')

    this.ballotID = _get(this, '$route.params.id', '')

    await this.loadAddressDropDownList()

    await this.loadBallotByID()

    if (
      this.ballot.writeInCandidatesEnabled === undefined ||
      (this.ballot.writeInCandidatesEnabled !== undefined &&
        this.ballot.writeInCandidatesEnabled === false)
    ) {
      console.debug('writeInCandidatesEnabled=' + this.ballot.writeInCandidatesEnabled)
      this.ballot.ballotCandidates = this.ballot.ballotCandidates.filter(f => f.isWriteIn !== true)
    }

    this.candidateCheckboxGroup = []
    this.candidateRadioGroup = []
    this.multipleChoiceCheckboxGroup = []
    this.responseCandidates = []
    this.responseMultipleChoices = []

    let initNumeric = true

    if (this.ballotResponse && this.ballotResponse !== undefined) {
      this.buttonText = 'Save'

      if (this.ballotResponseTemp === null) {
        this.ballotResponseTemp = this.ballotResponse
      }

      const address = this.addressDropDownList.filter(
        i => i.unitID === this.ballotResponseTemp.unitID
      )

      this.locationFilterQuery = address && address[0] && address[0].label ? address[0].label : ''

      const parsedVoteDataJson = JSON.parse(this.ballotResponseTemp.voteDataJson)

      const tempBallotType = _get(this.ballot, 'ballotType', '')

      if (parsedVoteDataJson) {
        console.debug('parsedVoteDataJson=' + JSON.stringify(parsedVoteDataJson))

        if (tempBallotType === 'yes-no') {
          console.debug('parsedVoteDataJson=' + parsedVoteDataJson.yesNoResponse)
          this.radio = parsedVoteDataJson.yesNoResponse
        } else if (tempBallotType === 'election') {
          if (
            parsedVoteDataJson.responseCandidates &&
            typeof parsedVoteDataJson.responseCandidates === 'object'
          ) {
            this.responseCandidates = parsedVoteDataJson.responseCandidates

            console.debug('this.responseCandidates=' + JSON.stringify(this.responseCandidates))

            if (this.responseCandidates) {
              this.responseCandidates.forEach(e => {
                if (e) {
                  initNumeric = false
                  this.candidateRadioGroup.push(e.ballotCandidateID)
                }
              })
            }
          }
        } else if (tempBallotType === 'multiple-choice') {
          if (
            parsedVoteDataJson.multipleChoiceOptions &&
            typeof parsedVoteDataJson.multipleChoiceOptions === 'object'
          ) {
            this.responseMultipleChoices = parsedVoteDataJson.multipleChoiceOptions
            this.responseMultipleChoices.forEach(e => {
              if (e) {
                this.multipleChoiceCheckboxGroup.push(e.ballotMultipleChoiceOptionID)
              }
            })
          }
        }
      }

      if (initNumeric === true) {
        await this.initializeNumeric()
      } else {
        await this.initializeNumericEdit()
      }

      if (this.ballotResponseTemp.ballotDocumentID) {
        this.attachment = true
        this.loadCurrentDocument()
      }
    } else {
      this.buttonText = 'Add'
      await this.initializeNumeric()
    }
  },

  async initializeNumericEdit() {
    //Note: if this needs to be more precise then use jquery selector on id via ballotCandidateID

    //initialize numeric inputs
    if (this.ballot && this.ballot.ballotCandidates && this.ballot.ballotCandidates.length > 0) {
      console.debug('in numeric initialize...')
      let counter = 0
      this.ballot.ballotCandidates.forEach(e => {
        if (e) {
          if (this.responseCandidates) {
            this.responseCandidates.forEach(r => {
              if (r) {
                if (r.ballotCandidateID === e.ballotCandidateID) {
                  this.candidateCheckboxGroup[counter] = r.numVotes
                } else if (!this.candidateCheckboxGroup[counter]) {
                  this.candidateCheckboxGroup[counter] = 0
                }
              }
            })
          }

          counter++
        }
      })
    }
  },

  async initializeNumeric() {
    //initialize numeric inputs
    if (this.ballot && this.ballot.ballotCandidates && this.ballot.ballotCandidates.length > 0) {
      console.debug('in initializeNumericEdit...')
      let counter = 0
      this.ballot.ballotCandidates.forEach(e => {
        if (e) {
          this.candidateCheckboxGroup[counter] = 0
          counter++
        }
      })
    }
  },

  async loadBallotByID() {
    await ballotStore
      .dispatch('getBallotById', {
        ballotID: this.ballotID
      })
      .then(async ({ result }) => {
        if (result) {
          let tempBallotType = _get(result, 'ballotType', '')
          if (tempBallotType === 'yes-no') {
            this.ballotType = 'Yes / No'
          } else if (tempBallotType === 'multiple-choice') {
            this.ballotType = 'Multiple Choice'
          } else if (tempBallotType === 'election') {
            this.ballotType = 'Election'
          } else {
            this.ballotType = ''
          }

          this.ballot = result

          console.debug('loadBallotByID=' + JSON.stringify(this.ballot))
        }
      })
  },

  async loadAddressDropDownList() {
    const hoaID = this.hoaId

    await hoaUtility
      .dispatch('loadDropDownAddressList', {
        hoaID
      })
      .then(({ list }) => {
        if (list) {
          this.addressDropDownList = list
        }
        if (this.addressDropDownList && this.addressDropDownList != undefined) {
          if (this.isDebug == true)
            console.debug('addressDropDownList=' + JSON.stringify(this.addressDropDownList))

          this.data = this.addressDropDownList

          console.debug('this.addressDropDownList=' + JSON.stringify(this.addressDropDownList))

          this.tempAddress = []
          if (
            this.ballot &&
            this.ballot !== undefined &&
            this.ballot.unitID &&
            this.ballot.unitID !== undefined &&
            this.addressDropDownList
          ) {
            this.tempAddress = this.addressDropDownList.filter(i => i.value === this.ballot.unitID)

            if (this.tempAddress && this.tempAddress !== undefined && this.tempAddress[0]) {
              this.locationFilterQuery = this.tempAddress[0].label
            }
          }
        }
      })
  },

  async loadCurrentDocument() {
    if (
      !this.ballotResponseTemp ||
      !this.ballotResponseTemp.ballotResponseID ||
      this.ballotResponseTemp.ballotResponseID <= 0
    ) {
      return
    }

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    try {
      const params = {
        ballotResponseID: this.ballotResponseTemp.ballotResponseID,
        asBase64: true
      }

      await ballotResponseStore.dispatch('getResponseFile', {
        params: params,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true)
              console.debug(
                ' - params' + JSON.stringify(params) + ' - this.getResponseFile=' + details
              )

            this.base64pdf = details
            this.imageContent = null
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
    }

    loadingComponent.close()
  },

  async submitFile() {
    if (!this.ballotResponseID && this.ballotResponseID <= 0) {
      notifyError('There was a problem uploading this document.')
      return
    }

    this.loading = true

    try {
      if (this.file) {
        const payload = {
          ballotResponseID: this.ballotResponseID,
          file: this.file
        }

        await ballotResponseStore.dispatch('uploadDocument', {
          payload: payload,
          done: ({ returnedFile }) => {
            if (returnedFile) {
              notifyMessage(`Successfully uploaded this response document.`)
            } else {
              notifyError('There was a problem uploading this response document.')
            }
          }
        })
      }
    } catch (e) {
      notifyError(e)
    }
    this.loading = false
  },

  async getBallotResponseById() {
    await ballotResponseStore
      .dispatch('getBallotResponseById', {
        ballotResponseID: this.ballotResponseID
      })
      .then(async ({ result }) => {
        if (result) {
          this.ballotResponseTemp = result
        }
      })
  },

  async replaceFile() {
    if (this.file) {
      this.ballotResponseID = this.ballotResponseTemp.ballotResponseID
      await this.submitFile()
      await this.getBallotResponseById()
      await this.reload()
    } else {
      notifyError('Please drag and drop a new file to replace the existing document.')
    }
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async onFormSubmit() {
    if (!this.formData || (this.formData === undefined && this.ballot)) {
      notifyError('There was a problemm saving this response.')
      return
    }

    console.debug('ballotType=' + this.ballot.ballotType)

    let tempVoteJson = ''
    if (this.ballot.ballotType === 'yes-no' && this.radio) {
      tempVoteJson = {
        yesNoResponse: this.radio
      }
    } else if (this.ballot.ballotType === 'election') {
      console.debug('election electionVotingIsCumulative=' + this.ballot.electionVotingIsCumulative)

      if (
        this.ballot.electionVotingIsCumulative === false &&
        this.candidateRadioGroup &&
        this.candidateRadioGroup.length > 0
      ) {
        console.debug('non-cumulative election....')
        console.debug('non-cumulative radio group=' + this.candidateRadioGroup.length)

        //Validate open positions
        if (this.ballot.numOfElectionPositions !== this.candidateRadioGroup.length) {
          this.candidateRadioGroup = []
          notifyError(
            `Cast no more or less than ${this.ballot.numOfElectionPositions || 0} vote(s).`
          )
          return
        }

        let checkedNonCumulatives = []
        if (this.candidateRadioGroup && this.candidateRadioGroup.length > 0) {
          this.candidateRadioGroup.forEach(e => {
            if (e) {
              checkedNonCumulatives.push({ ballotCandidateID: e, numVotes: 1 })
            }
          })
        }

        tempVoteJson = {
          responseCandidates: checkedNonCumulatives
        }
      } else {
        console.debug('cumulative election...........')
        console.debug('candidate radio=' + this.candidateCheckboxGroup)

        const summedLength = this.candidateCheckboxGroup.reduce((acc, item) => acc + item, 0)

        console.debug(summedLength + ', Cumulative Sums=' + this.candidateCheckboxGroup)

        if (this.ballot.numOfElectionPositions !== summedLength) {
          notifyError(
            `Cast no more or less than ${this.ballot.numOfElectionPositions || 0} vote(s).`
          )
          return
        }

        let checkedResponseCandidates = []
        if (this.candidateCheckboxGroup && this.candidateCheckboxGroup.length > 0) {
          let cumulativeCounter = 0

          this.candidateCheckboxGroup.forEach(e => {
            if (e) {
              let selectedBallotCandidateID = _get(
                this.ballot.ballotCandidates[cumulativeCounter],
                'ballotCandidateID',
                null
              )

              if (selectedBallotCandidateID) {
                checkedResponseCandidates.push({
                  ballotCandidateID: selectedBallotCandidateID,
                  numVotes: e
                })
              }
            }
            cumulativeCounter++
          })
        }

        tempVoteJson = {
          responseCandidates: checkedResponseCandidates
        }
      }
    } else if (this.ballot.ballotType === 'multiple-choice') {
      let checkedMultipleChoiceOptions = []

      if (this.multipleChoiceCheckboxGroup && this.multipleChoiceCheckboxGroup.length > 0) {
        //Validate maximum selectable
        if (
          this.multipleChoiceCheckboxGroup.length > this.ballot.multipleChoiceNumOptionsSelectable
        ) {
          notifyError(
            `Please select up to ${this.ballot.multipleChoiceNumOptionsSelectable || 0} choice(s).`
          )
          return
        }

        this.multipleChoiceCheckboxGroup.forEach(e => {
          if (e) {
            checkedMultipleChoiceOptions.push({ ballotMultipleChoiceOptionID: e })
          }
        })
      }

      tempVoteJson = {
        multipleChoiceOptions: checkedMultipleChoiceOptions
      }
    }

    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    try {
      const address = this.addressDropDownList.filter(i =>
        i.label.includes(this.locationFilterQuery)
      )

      const payload = {
        ballotID: this.ballotID,
        unitID: address && address[0] && address[0].unitID ? address[0].unitID : 0,
        ownerID: address && address[0] && address[0].primaryOwnerID ? address[0].primaryOwnerID : 0,
        ballotDocumentID: _get(this.ballotResponseTemp, 'ballotDocumentID', null),
        voteDataJson: JSON.stringify(tempVoteJson)
      }

      //validate whether this will be a second vote
      this.alreadyVoted = false
      await ballotResponseStore.dispatch('hasVotedonBallotResponseAlready', {
        ballotID: this.ballotID,
        unitID: payload.unitID,
        ownerID: payload.ownerID,
        done: async ({ detail }) => {
          if (detail) {
            if (detail === true) {
              this.alreadyVoted = true
            }
          }
        }
      })

      if (this.alreadyVoted === true) {
        notifyError('This ballot has already been voted on by this owner and for this location.')
        this.loading = false
        loadingComponent.close()
        return
      }

      console.debug('payload=' + JSON.stringify(payload))

      if (this.ballotResponseTemp && this.ballotResponseTemp !== undefined) {
        payload.ballotResponseID = this.ballotResponseTemp.ballotResponseID

        await ballotResponseStore.dispatch('updateBallotResponse', {
          payload,
          done: async ({ detail }) => {
            if (detail) {
              this.ballotResponseID = detail.ballotResponseID

              if (this.file && payload.ballotDocumentID == null) {
                this.submitFile()
              }

              this.closeModal()
            }
          }
        })
      } else {
        await ballotResponseStore.dispatch('addBallotResponse', {
          payload,
          done: async ({ detail }) => {
            if (detail) {
              console.debug('add candidate detail=' + JSON.stringify(detail))
              this.ballotResponseID = detail.ballotResponseID

              if (this.file) {
                this.submitFile()
              }

              this.closeModal()
            }
          }
        })
      }
    } catch (e) {
      notifyError(e)
    }

    loadingComponent.close()

    this.loading = false
  },

  zoom(direction) {
    const amt = Math.sqrt(2)
    if (direction > 0) {
      this.scale *= amt
    } else {
      this.scale /= amt
    }

    // constrain to min/max
    this.scale = Math.max(0.5, Math.min(4, this.scale))
  },

  getAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.data = []
    }

    // String cleared
    if (!name.length) {
      this.data = []
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.addressDropDownList) {
      this.data = this.addressDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching = false
  }, 500),

  getMoreAsyncData: debounce(function() {
    this.getAsyncData(this.name)
  }, 250),

  async addWriteIn() {
    if (!this.writeInName || this.writeInName === undefined) {
      notifyError('The write-in candidate name is required.')
      return
    }

    this.loading = true

    try {
      const payload = {
        ballotID: this.ballotID,
        name: this.writeInName,
        isWriteIn: true,
        resume: `Write-in Candidate for ${this.writeInName}`
      }

      console.log('payload=' + JSON.stringify(payload))

      await ballotCandidateStore.dispatch('addBallotCandidate', {
        payload,
        done: async ({ detail }) => {
          if (detail) {
            await this.reload()
            this.writeInName = ''
          }
        }
      })
    } catch (e) {
      notifyError(e)
    }
    this.loading = false
  }
}
