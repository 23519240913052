/*
  import { ballotMultipleStore } from '@/services/BallotMultipleChoiceOption/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await ballotMultipleStore.dispatch ('getBallotMultipleList', {
      ballotID
    });
  */

  async getBallotMultipleList({}, { ballotID }) {
    console.log('in getBallotMultipleList...' + ballotID)
    try {
      const results = await kms.get(`/EVoting/BallotMultipleChoiceOption/List?ballotID=${ballotID}`)

      if (isDebug == true) console.debug('getBallotMultipleList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The ballot multiple choice list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async deleteMultiple({ dispatch }, { ballotMultipleChoiceOptionID, done }) {
    try {
      const path = `/EVoting/BallotMultipleChoiceOption/${ballotMultipleChoiceOptionID}?forceDelete=false`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted this multiple choice option.`)
        done()
      } else {
        notifyError('There was a problem deleting this multiple choice option.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting this multiple choice option.`)
      console.error(exception)
    }
  },

  async addBallotMultiple({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in addBallotMultiple')
    try {
      const results = await kms.post(`/EVoting/BallotMultipleChoiceOption`, {
        ballotID: payload.ballotID,
        description: payload.description,
        displayOrder: payload.displayOrder
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.ballotID > 0) {
        notifyMessage(`Successfully added this multiple choice option.`)
        done()
      } else {
        notifyError('There was a problem adding this multiple choide option.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this multiple choice option.`)
      console.error(exception)
    }
  },

  async updateBallotMultiple({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/EVoting/BallotMultipleChoiceOption`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.ballotMultipleChoiceOptionID > 0) {
        notifyMessage(`Successfully updated this multiple choice option.`)
        done()
      } else {
        notifyError('There was a problem updating this multiple choice option.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this multiple choice option.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const ballotMultipleStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
