import { ballotCandidateStore } from '@/services/BallotCandidate/store'
import { notifyError, notifyMessage } from '@/services/notify'
import $ from 'jquery'
import _get from 'lodash/get'

export const methods = {
  async reload() {
    console.debug('in reload()')

    if (this.ballotCandidate && this.ballotCandidate !== undefined) {
      if (this.ballotCandidateTemp === null) {
        this.ballotCandidateTemp = this.ballotCandidate
      }

      this.buttonText = 'Save'
      this.formData.name = this.ballotCandidateTemp.name
      this.formData.resume = this.ballotCandidateTemp.resume
      this.formData.isWriteIn = this.ballotCandidateTemp.isWriteIn

      if (this.ballotCandidateTemp.resumeDocumentID) {
        this.attachment = true
        this.loadCurrentDocument()
      }
    } else {
      this.buttonText = 'Add'
    }
  },

  async loadCurrentDocument() {
    if (
      !this.ballotCandidateTemp ||
      !this.ballotCandidateTemp.ballotCandidateID ||
      this.ballotCandidateTemp.ballotCandidateID <= 0
    ) {
      return
    }

    try {
      const params = {
        ballotCandidateID: this.ballotCandidateTemp.ballotCandidateID,
        asBase64: true
      }

      await ballotCandidateStore.dispatch('getResumeFile', {
        params: params,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true)
              console.debug(' - params' + JSON.stringify(params) + ' - this.getFile=' + details)

            this.base64pdf = details
            this.imageContent = null
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
    }
  },

  async replaceFile() {
    if (this.file) {
      this.ballotCandidateID = this.ballotCandidateTemp.ballotCandidateID
      await this.submitFile()
      await this.getBallotCandidateById()
      await this.reload()
    } else {
      notifyError('Please drag and drop a new file to replace the existing document.')
    }
  },

  async submitFile() {
    if (!this.ballotCandidateID && this.ballotCandidateID <= 0) {
      notifyError('There was a problem uploading this resume.')
      return
    }

    console.debug('in submitFile...')

    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    try {
      if (this.file) {
        const payload = {
          ballotCandidateID: this.ballotCandidateID,
          file: this.file
        }

        await ballotCandidateStore.dispatch('uploadDocument', {
          payload: payload,
          done: ({ returnedFile }) => {
            if (returnedFile) {
              notifyMessage(`Successfully uploaded this resume.`)
            } else {
              notifyError('There was a problem uploading this resume.')
            }
          }
        })
      }
    } catch (e) {
      notifyError(e)
      loadingComponent.close()
    }
    this.loading = false
    loadingComponent.close()
  },

  async getBallotCandidateById() {
    await ballotCandidateStore
      .dispatch('getBallotCandidateById', {
        ballotCandidateID: this.ballotCandidateID
      })
      .then(async ({ result }) => {
        if (result) {
          this.ballotCandidateTemp = result
        }
      })
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async onFormSubmit() {
    if (!this.formData || this.formData === undefined) {
      notifyError('There was a problemm saving this candidate.')
      return
    }

    this.loading = true

    try {
      const payload = {
        ballotID: this.ballotID,
        name: _get(this.formData, 'name', ''),
        isWriteIn: _get(this.formData, 'isWriteIn', null),
        resume: _get(this.formData, 'resume', ''),
        resumeDocumentID:
          this.attachment === true ? _get(this.ballotCandidateTemp, 'resumeDocumentID', null) : null
      }

      console.log('payload=' + JSON.stringify(payload))

      if (this.ballotCandidateTemp && this.ballotCandidateTemp !== undefined) {
        payload.ballotCandidateID = this.ballotCandidate.ballotCandidateID

        await ballotCandidateStore.dispatch('updateBallotCandidate', {
          payload,
          done: async ({ detail }) => {
            if (detail) {
              this.ballotCandidateID = detail.ballotCandidateID

              if (this.file && !payload.resumeDocumentID) {
                this.submitFile()
              }

              this.closeModal()
            }
          }
        })
      } else {
        await ballotCandidateStore.dispatch('addBallotCandidate', {
          payload,
          done: async ({ detail }) => {
            if (detail) {
              console.debug('add candidate detail=' + JSON.stringify(detail))
              this.ballotCandidateID = detail.ballotCandidateID

              if (this.file) {
                await this.submitFile()
              }

              if (this.fromInclude === false) {
                this.closeModal()
              }
            }
          }
        })
      }
    } catch (e) {
      notifyError(e)
    }
    this.loading = false
  },

  zoom(direction) {
    const amt = Math.sqrt(2)
    if (direction > 0) {
      this.scale *= amt
    } else {
      this.scale /= amt
    }

    // constrain to min/max
    this.scale = Math.max(0.5, Math.min(4, this.scale))
  }
}
