import { ballotStore } from '@/services/Ballot/store'
import { ballotCandidateStore } from '@/services/BallotCandidate/store'
import { ballotMultipleStore } from '@/services/BallotMultipleChoiceOption/store'
import $ from 'jquery'
import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import kms from '@/services/kms'
import Download from '../components/Download'
import { notifyProblem, notifyMessage, notifyWarning, notifyError } from '@/services/notify'
import { base64toBlob, showPdf } from '@/services/blobutility'
import moment from 'moment'

export const methods = {
  async reload() {
    console.debug('in reload()')

    this.ballotID = this.$route.params.id

    await this.loadBallotByID()

    await this.getBallotTypeList()

    await this.loadBallotMultipleList()

    await this.loadBallotCandidateList()
  },

  async loadBallotByID() {
    await ballotStore
      .dispatch('getBallotById', {
        ballotID: this.ballotID
      })
      .then(async ({ result }) => {
        if (result) {
          console.debug('getBallotById result=' + JSON.stringify(result))

          let tempBallotType = _get(result, 'ballotType', '')
          if (tempBallotType === 'yes-no') {
            tempBallotType = 'yesno'
          } else if (tempBallotType === 'multiple-choice') {
            tempBallotType = 'multiplechoice'
          } else if (tempBallotType === 'election') {
            tempBallotType = 'election'
          } else {
            tempBallotType = ''
          }

          this.formData.description = _get(result, 'description', '')
          this.formData.fullDescription = _get(result, 'fullDescription', '')
          this.formData.title = _get(result, 'title', '')
          this.formData.ballotTypeCode = tempBallotType

          this.orignalBallotType = this.formData.ballotTypeCode

          console.debug('ballotTypeCode=' + this.formData.ballotTypeCode)
          this.formData.noOptionDescription = _get(result, 'noOptionDescription', '')
          this.formData.yesOptionDescription = _get(result, 'yesOptionDescription', '')
          this.formData.electionCumulation =
            _get(result, 'electionVotingIsCumulative', false) === true
              ? 'cumulative'
              : 'nonCumulative'
          this.formData.numOfOptions = _get(result, 'multipleChoiceNumOptionsSelectable', 0)
          this.formData.numOfElectionPositions = _get(result, 'numOfElectionPositions', 0)
          this.formData.completeByDate = _get(result, 'completeByDate', null)
          this.formData.publishedDate = _get(result, 'publishedDate', null)
          this.isFinalized = _get(result, 'isFinalized', false)
          this.formData.isSecret = _get(result, 'isSecret', false)
          this.formData.allowWriteIn = _get(result, 'writeInCandidatesEnabled', false)
        }
      })
  },

  async loadBallotCandidateList() {
    await ballotCandidateStore
      .dispatch('getBallotCandidateList', {
        ballotID: this.ballotID,
        includeWriteIns: true
      })
      .then(({ list }) => {
        this.determineCandidateRows(list)
        this.loading = false
      })
  },

  determineCandidateRows: function(list) {
    const dateFormat = 'LL'

    let filteredList = list

    var rows = filteredList.map(entry => {
      const name = _get(entry, 'name', '')
      const resume = _get(entry, 'resume', '')
      const createdDate = parseDate(_get(entry, 'createdDate', null), dateFormat)
      const tempWriteIn = _get(entry, 'isWriteIn', false)
      let writeIn = 'no'
      if (tempWriteIn && tempWriteIn === true) {
        writeIn = 'yes'
      }

      return {
        name,
        resume,
        isWriteIn: writeIn,
        createdDate,
        details: {
          component: Button,
          props: {
            onClick: () => {
              this.processCandidateDetail(entry)
            },
            text: 'Edit'
          }
        },
        erase: {
          component: Button,
          props: {
            onClick: () => {
              this.confirmDeleteCandidateBallot(entry)
            },
            text: 'Delete'
          }
        },
        document: {
          component: Download,
          props: {
            documentID: _get(entry, ['resumeDocumentID'], 0),
            ballotCandidateID: _get(entry, 'ballotCandidateID', 0),
            downloadPdf: ballotCandidateID => {
              this.downloadPdf(ballotCandidateID)
            }
          }
        }
      }
    })

    this.rows = rows
  },

  async downloadPdf(ballotCandidateID) {
    if (this.isDebug == true) console.debug('downloadPdf...')

    if (!ballotCandidateID || ballotCandidateID == undefined) {
      notifyProblem('There was a problem downloading this candidate resume.')
    }

    try {
      const params = {
        ballotCandidateID: ballotCandidateID,
        asBase64: true,
        asRawBase64: true
      }

      const path = `/EVoting/BallotCandidate/GetFile`
      const returned = await kms.get(path, {
        params
      })

      if (this.isDebug == true)
        console.debug(
          'path base64 path=' +
            path +
            ' - params' +
            JSON.stringify(params) +
            ' - returned=' +
            JSON.stringify(returned)
        )
      if (returned && returned != undefined) {
        if (this.isDebug == true) console.debug('base64pdf........................' + returned)
        const contentType = 'application/pdf'
        const blob = base64toBlob(returned, contentType)
        showPdf(blob)
        notifyMessage(`Successfully downloaded this candidate's resume.`)
        return
      }
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading this resume.')
    }
  },

  async confirmDeleteCandidateBallot(entry) {
    if (this.formData.ballotTypeCode !== this.orignalBallotType) {
      notifyError(
        'Please click Save prior to deleting this candidate. You changed the ballot type.'
      )
      return
    }

    this.$buefy.dialog.confirm({
      title: 'Deleting Ballot Candidate',
      message: `Are you sure you want to <b>delete</b> this candidate, "${entry.name}"?`,
      confirmText: 'Delete Ballot Candidate',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => await this.deleteBallotCandidate(entry.ballotCandidateID)
    })
  },

  async deleteBallotCandidate(ballotCandidateID) {
    try {
      await ballotCandidateStore.dispatch('deleteBallotCandidate', {
        ballotCandidateID,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    } catch (e) {
      console.debug('There was an issue deleting this candidate.')
    }
  },

  async processCandidateDetail(entry) {
    if (this.formData.ballotTypeCode !== this.orignalBallotType) {
      notifyError(
        'Please click Save prior to updating this candidate. You changed the ballot type.'
      )
      return
    }

    this.selectedBallotCandidate = entry
    this.toggle = true
  },

  async loadBallotMultipleList() {
    await ballotMultipleStore
      .dispatch('getBallotMultipleList', {
        ballotID: this.ballotID
      })
      .then(({ list }) => {
        this.determineMultipleRows(list)
        this.loading = false

        this.numberOfChoices = list.length
      })
  },

  determineMultipleRows: function(list) {
    const dateFormat = 'LL'

    let filteredList = list

    var rows = filteredList.map(entry => {
      const description = _get(entry, 'description', '')
      const displayOrder = _get(entry, 'displayOrder', 0)
      const createdDate = parseDate(_get(entry, 'createdDate', null), dateFormat)

      return {
        displayOrder,
        description,
        createdDate,
        details: {
          component: Button,
          props: {
            onClick: () => {
              this.processMultipleDetail(entry)
            },
            text: 'Edit'
          }
        },
        erase: {
          component: Button,
          props: {
            onClick: () => {
              this.confirmDeleteMultiple(entry)
            },
            text: 'Delete'
          }
        }
      }
    })

    this.rows2 = rows
  },

  async confirmDeleteMultiple(entry) {
    if (this.formData.ballotTypeCode !== this.orignalBallotType) {
      notifyError('Please click Save prior to deleting this option. You changed the ballot type.')
      return
    }

    this.$buefy.dialog.confirm({
      title: 'Deleting Option',
      message: `Are you sure you want to <b>delete</b> this option, "${entry.description}"?`,
      confirmText: 'Delete Multiple Choice Option',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => await this.deleteMultiple(entry.ballotMultipleChoiceOptionID)
    })
  },

  async deleteMultiple(ballotMultipleChoiceOptionID) {
    try {
      await ballotMultipleStore.dispatch('deleteMultiple', {
        ballotMultipleChoiceOptionID,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    } catch (e) {
      console.debug('There was an issue deleting this multiple choice option.')
    }
  },

  async processMultipleDetail(entry) {
    if (this.formData.ballotTypeCode !== this.orignalBallotType) {
      notifyError('Please click Save prior to updating this option. You changed the ballot type.')
      return
    }

    this.selectedBallotMultiple = entry
    this.toggle2 = true
  },

  async getBallotTypeList() {
    console.debug('in getBallotTypeList()')

    this.loading = true
    await ballotStore.dispatch('getBallotTypeList').then(({ list }) => {
      if (list) {
        this.ballotTypeList = list
      }
    })

    this.loading = false
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async onFormSubmit() {
    if (!this.formData) {
      notifyError('There was a problem saving this ballot.')
      return
    }

    this.loading = true

    if (!this.formData.completeByDate) {
      notifyError('Please enter a complete by Date.')
      return
    }

    try {
      let tempBallotTypeUpdate = ''
      if (this.formData.ballotTypeCode) {
        if (this.formData.ballotTypeCode === 'yesno') {
          tempBallotTypeUpdate = 'yes-no'
          this.formData.writeInCandidatesEnabled = false
        } else if (this.formData.ballotTypeCode === 'election') {
          tempBallotTypeUpdate = 'election'
        } else if (this.formData.ballotTypeCode === 'multiplechoice') {
          tempBallotTypeUpdate = 'multiple-choice'

          this.formData.writeInCandidatesEnabled = false

          console.debug('numOptions=' + this.numberOfChoices)
          if (
            this.formData.numOfOptions !== 1 &&
            this.formData.numOfOptions > this.numberOfChoices
          ) {
            notifyError(
              `Please make sure that the number of selectable multiple choice options is at least one and less than the total number of multiple choice options listed below (${this.numberOfChoices}).`
            )
            return
          }
        }

        this.orignalBallotType = this.formData.ballotTypeCode
      }

      let processedPublishDate = _get(this.formData, 'publishedDate', null)
      if (processedPublishDate) {
        //Make sure it is published at the first hour of the day
        processedPublishDate = moment(processedPublishDate).format('YYYY-MM-DD') + 'T01:00:00.000'
      }

      let processedCompleteByDate = _get(this.formData, 'completeByDate', null)
      if (processedCompleteByDate) {
        //Make sure it is published at the first hour of the day
        processedCompleteByDate =
          moment(processedCompleteByDate).format('YYYY-MM-DD') + 'T23:59:59.999'
      }

      const payload = {
        ballotID: this.ballotID,
        hoaID: this.hoaId,
        description: _get(this.formData, 'description', ''),
        fullDescription: _get(this.formData, 'fullDescription', ''),
        title: _get(this.formData, 'title', null),
        ballotType: tempBallotTypeUpdate,
        yesOptionDescription:
          tempBallotTypeUpdate === 'yes-no'
            ? _get(this.formData, 'yesOptionDescription', null)
            : null,
        noOptionDescription:
          tempBallotTypeUpdate === 'yes-no'
            ? _get(this.formData, 'noOptionDescription', null)
            : null,
        multipleChoiceNumOptionsSelectable:
          tempBallotTypeUpdate === 'multiple-choice'
            ? _get(this.formData, 'numOfOptions', null)
            : null,
        numOfElectionPositions:
          tempBallotTypeUpdate === 'election'
            ? _get(this.formData, 'numOfElectionPositions', null)
            : null,
        electionVotingIsCumulative:
          this.formData.electionCumulation && this.formData.electionCumulation === 'cumulative'
            ? true
            : false,
        completeByDate: processedCompleteByDate,
        isFinalized: this.isFinalized,
        isSecret: this.formData.isSecret,
        writeInCandidatesEnabled: _get(this.formData, 'allowWriteIn', false),
        publishedDate: processedPublishDate
      }

      console.log('payload=' + JSON.stringify(payload))

      await ballotStore.dispatch('updateBallot', {
        payload,
        done: () => {
          this.closeModal()
        }
      })
    } catch (e) {
      notifyError(e)
    }
    this.loading = false
  },

  async finalize() {
    console.debug('finalize...')
    this.isFinalized = true
    this.onFormSubmit()
  },

  async createBallotCandidateModal() {
    if (this.formData.ballotTypeCode !== this.orignalBallotType) {
      notifyError('Please click Save prior to adding a new candidate. You changed the ballot type.')
      return
    }

    this.selectedBallotCandidate = null
    this.toggle = true
  },

  async createBallotMultipleModal() {
    if (this.formData.ballotTypeCode !== this.orignalBallotType) {
      notifyError(
        'Please click Save prior to adding a new multiple choice option. You changed the ballot type.'
      )
      return
    }

    this.selectedBallotMultiple = null
    this.toggle2 = true
  }
}
