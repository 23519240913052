<template>
  <div ballot-candidate-modal id="ballotModal" class="section">
    <div class="container">
      <div class="is-size-4" v-if="fromInclude === false">
        {{ `${buttonText} Ballot Candidate` }}
      </div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="Name"
                    label="Name"
                    type="text"
                    vid="Name"
                    placeholder="Name"
                    spellcheck="true"
                    aria-label="Name"
                    rules="required|max:50"
                    class="is-small"
                    v-model="formData.name"
                  />
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="Resume"
                    label="Resume"
                    type="textarea"
                    vid="Resume"
                    placeholder="Resume"
                    spellcheck="true"
                    aria-label="Resume"
                    rules="required|max:8000"
                    class="is-small"
                    v-model="formData.resume"
                  />
                </div>
                <div class="pt-5">
                  <b-checkbox v-model="formData.isWriteIn" :native-value="true">
                    Write-In Candidate
                  </b-checkbox>
                </div>
                <div class="pt-5" v-if="fromInclude === false">
                  <section class="box" v-if="base64pdf">
                    <div :style="{ textAlign: 'left' }" id="viewpdf" class="has-text-centered">
                      <div>
                        <div>
                          <div
                            v-dragscroll="true"
                            :class="['doc-viewer', { 'zoom-active': scale > 0.9 }]"
                          >
                            <pdf
                              style="display: none;"
                              :src="base64pdf"
                              @num-pages="pageCount = new Array($event)"
                            />
                            <!-- Actual document -->
                            <pdf
                              v-for="(page, index) in pageCount"
                              :key="index"
                              :src="`${base64pdf}`"
                              :page="index + 1"
                              :scale="scale"
                              :resize="true"
                              :style="{ margin: 'auto', width: `${100 * scale}%` }"
                            />
                          </div>
                        </div>
                        <div class="doc-controls has-text-center">
                          <b-field position="is-centered">
                            <div class="control">
                              <b-button @click="zoom(-1)">-</b-button>
                            </div>
                            <div class="control">
                              <b-button @click="zoom(1)">+</b-button>
                            </div>
                          </b-field>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div>
                    <!-- Show the Upload Form -->
                    <b-field class="file">
                      <b-upload v-model="file" drag-drop accept="application/pdf" class="rectangle">
                        <section class="section">
                          <div class="">
                            <div>
                              <img
                                class="Fill-1-Copy-11"
                                src="@/assets/images/fill-1-copy-11.svg"
                                alt="Upload"
                              />
                            </div>
                            <div v-if="file">
                              {{ file.name }}
                            </div>
                            <div v-else>
                              <div class="Drag-Drop-your-fil">
                                <div class="text-style-1">Drag & Drop</div>
                                <div>your files here to upload</div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>
                  </div>
                </div>
                <div class="pt-6">
                  <button
                    type="submit"
                    :class="`button ${fromInclude === false ? 'is-primary' : 'is-info'}`"
                  >
                    {{ buttonText }}</button
                  ><span class="pl-3">&nbsp;</span>
                  <b-button v-if="base64pdf" @click.prevent="replaceFile()" type="is-primary">
                    Replace </b-button
                  ><span v-if="fromInclude === false"
                    ><span class="pl-3">&nbsp;</span>
                    <b-button @click.prevent="closeModal()" type="is-primary">
                      Close
                    </b-button></span
                  >
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'
import pdf from 'vue-pdf'
import { dragscroll } from 'vue-dragscroll'

export default {
  name: 'BallotCandidateModal',
  props: {
    ballotID: Number,
    ballotCandidate: Object,
    fromInclude: {
      type: Boolean,
      default: false
    }
  },

  directives: { dragscroll },

  components: {
    ValidationObserver,
    ValidationProvider,
    ValidInput,
    pdf
  },

  mounted() {
    this.reload()
    if (this.fromInclude === true) {
      this.formData.isWriteIn = true
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
<style lang="scss"></style>
