export const data = () => ({
  isDebug: true,
  isOpen: false,
  loading: true,
  isFetching: false,
  openOnFocus: true,
  toggle: false,
  ballot: null,
  buttonText: 'Add',
  ballotResponseList: [],
  ballotID: 0,
  selectedBallotResponse: null,
  isHomeOwner: false,
  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'ownerName',
      label: 'Owner',
      aria: 'Owner',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'unitAddress',
      label: 'Unit',
      aria: 'Unit',
      width: '30%',
      sortable: true,
      searchable: true
    },
    {
      field: 'vote',
      label: 'Vote',
      aria: 'Vote',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Created',
      aria: 'Created',
      date: true,
      width: '10%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
