export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    description: '',
    buttonText: 'Add',
    file: null,
    base64pdf: '',
    pageCount: [],
    scale: 1,
    imageContent: null,
    attachment: false,
    ballotCandidateID: 0,
    ballotCandidateTemp: null,
    formData: {
      name: '',
      resume: '',
      isWriteIn: false
    }
  }
}
