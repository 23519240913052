export const data = () => ({
  isDebug: true,
  isOpen: false,
  loading: true,
  isFetching: false,
  openOnFocus: true,
  toggle: false,
  toggle2: false,
  ballot: null,
  description: '',
  buttonText: 'Add',
  ballotTypeList: [],
  ballotID: 0,
  isFinalized: false,
  selectedBallotCandidate: null,
  selectedBallotMultiple: null,
  orignalBallotType: '',
  numberOfChoices: 0,
  radioElection: 'nonCumulative',
  formData: {
    description: '',
    fullDescription: '',
    title: '',
    ballotTypeCode: '',
    noOptionDescription: '',
    yesOptionDescription: '',
    electionCumulation: 'nonCumulative',
    numOfOptions: 0,
    numOfElectionPositions: 0,
    completeByDate: null,
    publishedDate: null,
    isSecret: false,
    allowWriteIn: false
  },

  filters: {
    show: false
  },

  region: {},

  region2: {},

  rows: [],

  rows2: [],

  columns: [
    {
      field: 'name',
      label: 'Name',
      aria: 'Name',
      width: '30%',
      sortable: true,
      searchable: true
    },
    {
      field: 'resume',
      label: 'Resume',
      aria: 'Resume',
      width: '50%',
      sortable: true,
      searchable: true
    },
    {
      field: 'isWriteIn',
      label: 'Write In',
      aria: 'Write In',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Created',
      aria: 'Created',
      date: true,
      width: '10%',
      sortable: true,
      searchable: true
    }
  ],

  columns2: [
    {
      field: 'displayOrder',
      label: 'Display Order',
      aria: 'Display Order',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'description',
      label: 'Description',
      aria: 'Description',
      width: '60%',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Created',
      aria: 'Created',
      date: true,
      width: '10%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
