import { ballotMultipleStore } from '@/services/BallotMultipleChoiceOption/store'
import { notifyError } from '@/services/notify'
import $ from 'jquery'
import _get from 'lodash/get'

export const methods = {
  async reload() {
    console.debug('in reload()')

    if (this.ballotMultiple && this.ballotMultiple !== undefined) {
      this.buttonText = 'Save'
      this.formData.description = this.ballotMultiple.description
      this.formData.displayOrder = this.ballotMultiple.displayOrder
    } else {
      this.buttonText = 'Add'
    }
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async onFormSubmit() {
    if (!this.formData || this.formData === undefined) {
      notifyError('There was a problemm saving this multiple choice option.')
      return
    }

    this.loading = true

    try {
      const payload = {
        ballotID: this.ballotID,
        description: _get(this.formData, 'description', ''),
        displayOrder: _get(this.formData, 'displayOrder', 0),
        numOfElectionPositions: _get(this.formData, 'numOfElectionPositions', 0)
      }

      console.log('payload=' + JSON.stringify(payload))

      if (this.ballotMultiple && this.ballotMultiple !== undefined) {
        payload.ballotMultipleChoiceOptionID = this.ballotMultiple.ballotMultipleChoiceOptionID

        await ballotMultipleStore.dispatch('updateBallotMultiple', {
          payload,
          done: () => {
            this.closeModal()
          }
        })
      } else {
        await ballotMultipleStore.dispatch('addBallotMultiple', {
          payload,
          done: () => {
            this.closeModal()
          }
        })
      }
    } catch (e) {
      notifyError(e)
    }
    this.loading = false
  }
}
