<template>
  <div ballot-multiple-modal id="ballotModal" class="section">
    <div class="container">
      <div class="is-size-4">{{ `${buttonText} Ballot Multiple Choice Option` }}</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="is-12">
                  <valid-input
                    style="width:50px !important;"
                    name="Description"
                    label="Description"
                    type="text"
                    vid="Description"
                    placeholder="Description"
                    spellcheck="true"
                    aria-label="Description"
                    rules="required|max:200"
                    class="is-small"
                    v-model="formData.description"
                  />
                </div>
                <div class="is-12 pt-5">
                  <b-field label="Display Order">
                    <b-numberinput
                      name="formData.displayOrder"
                      vid="displayOrder"
                      v-model="formData.displayOrder"
                      required
                    />
                  </b-field>
                </div>
                <div class="pt-6">
                  <button type="submit" class="button is-primary is-rounded">
                    {{ buttonText }}</button
                  ><span class="pl-3">&nbsp;</span>
                  <b-button @click.prevent="closeModal()" type="is-primary">
                    Close
                  </b-button>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  name: 'BallotMultipleChoiceModal',
  props: {
    ballotID: Number,
    ballotMultiple: Object
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    ValidInput
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
<style lang="scss"></style>
