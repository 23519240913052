<template>
  <div ballot-modal id="ballotModal" class="section">
    <div class="container">
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div>
                  <label class="label">Complete By Date</label>
                  <DatePicker
                    v-model="formData.completeByDate"
                    ariaLabel="Complete By Date"
                    ref="completeByDateComponent"
                  />
                </div>
                <div class="pt-5">
                  <label class="label">Published Date</label>
                  <DatePicker
                    v-model="formData.publishedDate"
                    ariaLabel="Published Date"
                    ref="publishedDateComponent"
                  />
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="Description"
                    label="Description"
                    type="text"
                    vid="Description"
                    placeholder="Description"
                    spellcheck="true"
                    aria-label="Description"
                    rules="required|max:50"
                    class="is-small"
                    v-model="formData.description"
                  />
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="FullDescription"
                    label="Full Description"
                    type="textarea"
                    vid="FullDescription"
                    placeholder="Full Description"
                    spellcheck="true"
                    aria-label="Full Description"
                    rules="required|max:8000"
                    class="is-small"
                    v-model="formData.fullDescription"
                  />
                </div>
                <div class="is-12 pt-5">
                  <valid-input
                    style="width:50px !important;"
                    name="Title"
                    label="Title"
                    type="text"
                    vid="Title"
                    placeholder="Title"
                    spellcheck="true"
                    aria-label="Title"
                    rules="required|max:255"
                    class="is-small"
                    v-model="formData.title"
                  />
                </div>
                <div class="pt-5">
                  <b-checkbox v-model="formData.isSecret" :native-value="true">
                    Secret Ballot (Hide Voter Identity on Ballot)
                  </b-checkbox>
                </div>
                <div class="pt-5" v-if="formData.ballotTypeCode == 'election'">
                  <b-checkbox v-model="formData.allowWriteIn" :native-value="true">
                    Allow Write-in Candidates
                  </b-checkbox>
                </div>
                <div class="pb-5 pt-5">
                  <b-field label="Select Ballot Type">
                    <b-select
                      v-model="formData.ballotTypeCode"
                      required="true"
                      placeholder="Select a Ballot Type"
                      :disabled="isFinalized"
                    >
                      <option
                        v-for="option in ballotTypeList"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="is-12 pt-5" v-if="formData.ballotTypeCode === 'yesno'">
                  <valid-input
                    style="width:50px !important;"
                    name="YesOptionDescription"
                    label="Yes Option Description"
                    type="textarea"
                    vid="YesOptionDescription"
                    placeholder="Yes Option Description"
                    spellcheck="true"
                    aria-label="Yes Option Description"
                    rules="required:max:200"
                    class="is-small"
                    v-model="formData.yesOptionDescription"
                  />
                </div>
                <div class="is-12 pt-5" v-if="formData.ballotTypeCode === 'yesno'">
                  <valid-input
                    style="width:50px !important;"
                    name="NoOptionDescription"
                    label="No Option Description"
                    type="textarea"
                    vid="NoOptionDescription"
                    placeholder="No Option Description"
                    spellcheck="true"
                    aria-label="No Option Description"
                    rules="required:max:200"
                    class="is-small"
                    v-model="formData.noOptionDescription"
                  />
                </div>
                <div v-if="formData.ballotTypeCode === 'election'">
                  <b-radio
                    v-model="formData.electionCumulation"
                    name="radioElection"
                    native-value="cumulative"
                  >
                    Cumulative
                  </b-radio>
                </div>
                <div v-if="formData.ballotTypeCode === 'election'">
                  <b-radio
                    v-model="formData.electionCumulation"
                    name="radioElection"
                    native-value="nonCumulative"
                  >
                    Non-Cumulative
                  </b-radio>
                </div>
                <div class="pt-5" v-if="formData.ballotTypeCode === 'election'">
                  <b-field label="Number of Open Election Positions">
                    <b-numberinput
                      name="formData.numOfElectionPositions"
                      vid="numOfElectionPositions"
                      v-model="formData.numOfElectionPositions"
                      required
                    />
                  </b-field>
                </div>
                <div v-if="formData.ballotTypeCode === 'multiplechoice'">
                  <b-field label="Number of Multiple Choice Options">
                    <b-numberinput
                      name="formData.numOfOptions"
                      vid="numOfOptions"
                      v-model="formData.numOfOptions"
                      required
                    />
                  </b-field>
                </div>
                <div class="pt-6">
                  <button type="submit" class="button is-primary is-rounded">
                    Save
                  </button>
                  <span class="pl-3"></span>
                  <button
                    :disabled="isFinalized"
                    type="button"
                    class="button is-primary is-rounded"
                    @click.prevent="finalize()"
                  >
                    Finalize
                  </button>
                </div>
                <div class="pb-3 pt-6" v-if="formData.ballotTypeCode === 'election'">
                  <b-button
                    @click.prevent="createBallotCandidateModal()"
                    type="is-primary"
                    size="is-small"
                    rounded
                    :style="{
                      ...(breakpoint === 'desktop'
                        ? {}
                        : {
                            width: '100%'
                          })
                    }"
                  >
                    Add Candidate
                  </b-button>
                </div>
                <div v-if="formData.ballotTypeCode === 'election'">
                  <ModernTable
                    :region="region"
                    :filters="filters"
                    :rows="rows"
                    :columns="columns"
                    :pagination="{
                      perPage: 12
                    }"
                    :loading="loading"
                  />
                </div>

                <div class="pb-3 pt-6" v-if="formData.ballotTypeCode === 'multiplechoice'">
                  <b-button
                    @click.prevent="createBallotMultipleModal()"
                    type="is-primary"
                    size="is-small"
                    rounded
                    :style="{
                      ...(breakpoint === 'desktop'
                        ? {}
                        : {
                            width: '100%'
                          })
                    }"
                  >
                    Add Multiple Choice
                  </b-button>
                </div>
                <div v-if="formData.ballotTypeCode === 'multiplechoice'">
                  <ModernTable
                    :region="region2"
                    :filters="filters"
                    :rows="rows2"
                    :columns="columns2"
                    :pagination="{
                      perPage: 12
                    }"
                    :loading="loading"
                  />
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
    <Modal :toggle.sync="toggle">
      <BallotCandidateModal
        :ballotCandidate="selectedBallotCandidate"
        :ballotID="parseInt(ballotID)"
      >
      </BallotCandidateModal>
    </Modal>
    <Modal :toggle.sync="toggle2">
      <BallotMultipleChoiceModal
        :ballotMultiple="selectedBallotMultiple"
        :ballotID="parseInt(ballotID)"
      >
      </BallotMultipleChoiceModal>
    </Modal>
  </div>
</template>

<script>
import ModernTable from '@/components/tables/Modern/Table'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'
import DatePicker from '@/components/inputs/DatePicker'
import BallotCandidateModal from './components/ballotCandidateModal'
import BallotMultipleChoiceModal from './components/ballotMultipleChoiceModal'
import Modal from '@/components/Modal'

export default {
  name: 'EvotingDetails',
  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    DatePicker,
    ModernTable,
    Modal,
    BallotCandidateModal,
    BallotMultipleChoiceModal
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser']),
      breakpoint: state => (state && state.win ? state.win.breakpoint : null)
    })
  },

  created() {},

  mounted() {
    this.reload()
  },

  watch: {
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    },
    toggle2() {
      if (this.toggle2 == false) {
        this.reload()
        this.toggle2 = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  methods,

  i18n: {
    messages: {
      en: {
        evotingDetail: { title: 'eVoting Details' }
      }
    }
  }
}
</script>
