import { ballotStore } from '@/services/Ballot/store'
import { notifyError } from '@/services/notify'
import $ from 'jquery'
import _get from 'lodash/get'
import moment from 'moment'

export const methods = {
  async reload() {
    console.debug('in reload()')

    this.ballotID = _get(this, '$route.params.id', null)

    await this.getBallotTypeList()
  },

  async getBallotById() {},

  async getBallotTypeList() {
    console.debug('in getBallotTypeList()')
    this.loading = true
    await ballotStore.dispatch('getBallotTypeList').then(({ list }) => {
      if (list) {
        this.ballotTypeList = list
      }
    })

    this.loading = false
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async onFormSubmit() {
    if ((!this.ballot || this.ballot === undefined) && this.formData) {
      this.loading = true

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      if (!this.formData.completeByDate) {
        notifyError('Please enter a complete by Date.')
        return
      }

      try {
        let tempBallotType = ''
        if (this.formData.ballotTypeCode) {
          if (this.formData.ballotTypeCode === 'yesno') {
            tempBallotType = 'yes-no'
          } else if (this.formData.ballotTypeCode === 'election') {
            tempBallotType = 'election'
          } else if (this.formData.ballotTypeCode === 'multiplechoice') {
            tempBallotType = 'multiple-choice'
          }
        }

        let processedCompleteByDate = _get(this.formData, 'completeByDate', null)
        if (processedCompleteByDate) {
          //Make sure it is published at the first hour of the day
          processedCompleteByDate =
            moment(processedCompleteByDate).format('YYYY-MM-DD') + 'T23:59:59.999'
        }

        const payload = {
          hoaID: this.hoaId,
          description: _get(this.formData, 'description', ''),
          fullDescription: _get(this.formData, 'fullDescription', ''),
          title: _get(this.formData, 'title', null),
          ballotType: tempBallotType,
          yesOptionDescription: _get(this.formData, 'yesOptionDescription', null),
          noOptionDescription: _get(this.formData, 'noOptionDescription', null),
          multipleChoiceNumOptionsSelectable: _get(this.formData, 'numOfOptions', null),
          electionVotingIsCumulative:
            this.formData.electionCumulation && this.formData.electionCumulation === 'cumulative'
              ? true
              : false,
          numOfElectionPositions: _get(this.formData, 'numOfElectionPositions', null),
          completeByDate: processedCompleteByDate,
          isSecret: _get(this.formData, 'isSecret', false)
        }

        console.log('payload=' + JSON.stringify(payload))

        await ballotStore.dispatch('addBallot', {
          payload,
          done: ({ detail }) => {
            if (detail) {
              console.debug('added ballot=' + JSON.stringify(detail))
              this.redirectToDetails(detail)
            }
            this.closeModal()
          }
        })
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
      loadingComponent.close()
    }
  }
}
