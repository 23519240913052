export const data = function() {
  return {
    isDebug: true,
    loading: false,
    ballotID: 0,
    tabs: [
      { name: 'evotingDetails', label: 'Details' },
      { name: 'evotingResponses', label: 'Responses' }
    ],
    styles: {
      mainContainer: {
        minHeight: '1000px'
      }
    },
    transitionName: 'slide-down',
    showUploadModal: false
  }
}
